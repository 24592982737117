var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
let InputLabel = class InputLabel extends Vue {
    get disabledWithContent() {
        return this.currentModel && this.disable;
    }
    get disabledOpacity() {
        return this.disable;
    }
};
__decorate([
    PropSync('model', { default: '' })
], InputLabel.prototype, "currentModel", void 0);
__decorate([
    Prop({ default: '' })
], InputLabel.prototype, "labelName", void 0);
__decorate([
    Prop({ default: false })
], InputLabel.prototype, "disable", void 0);
__decorate([
    Prop({ default: false })
], InputLabel.prototype, "required", void 0);
__decorate([
    Prop({ default: '' })
], InputLabel.prototype, "mask", void 0);
__decorate([
    Prop({ default: false })
], InputLabel.prototype, "uppercase", void 0);
__decorate([
    Prop({ default: false })
], InputLabel.prototype, "errorFlag", void 0);
InputLabel = __decorate([
    Component({
        components: {},
    })
], InputLabel);
export default InputLabel;
