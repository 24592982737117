var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch } from 'vue-property-decorator';
import IconFiscalNote from '@/components/Icons/IconFiscalNote.vue';
import IconDocument from '@/components/Icons/IconDocument.vue';
import IconBoleto from '@/components/Icons/IconBoleto.vue';
import IconArrowDown from '@/components/Icons/IconArrowDown.vue';
import { getDateByFormat, formatCurrency } from '@/shared/utils/helpers';
import LoadingDots from '@/components/LoadingDots.vue';
import { connect } from '@/overmind';
import Tooltip from '@/components/Tooltip.vue';
import IconRefresh from '@/components/Icons/IconRefresh.vue';
let AccountInformationList = class AccountInformationList extends Vue {
    constructor() {
        super(...arguments);
        this.paymentList = [];
        this.filterTypes = ['Item', 'Total', 'Data venc.', 'Status'];
        this.filterMonthTypes = [];
        this.currentMonth = 'Todo o período';
        this.currentFilter = 'Data venc.';
        this.filterDirection = 'down';
        this.loadingList = true;
        this.pageError = false;
        //usar para calcular filtro por tempo
        this.today = new Date();
        this.filterByExpiration = arr => arr.filter(({ expiration_date }) => new Date(expiration_date.replace(/-/g, '/')) > this.today);
        this.paymentType = {
            waiting: 'Em aberto',
            approved: 'Pago',
            cancelled: 'Cancelado',
            fail: 'Falhou',
        };
        this.customNamesForPlans = {
            'Usuários permitidos': 'Compra - Usuário Adicional',
            'app_speed_mail - percent - SpeedMail': 'Aplicativo: SpeedMail',
        };
        this.fiscalNoteStatus = {
            cancelled: 'Essa nota fiscal foi cancelada',
            pending: 'Aguardando nota fiscal',
        };
    }
    get FilterByDate() {
        return this.listSorting('new Date(a.expiration_date)', 'new Date(b.expiration_date)');
    }
    get FilterByPrice() {
        return this.listSorting('a.total_price', 'b.total_price');
    }
    get FilterByTittle() {
        return this.listSorting('b.tittle', 'a.tittle');
    }
    get FilterByStatus() {
        return this.listSorting('b.status', 'a.status');
    }
    formatPrice(val) {
        return formatCurrency(val);
    }
    listSorting(comparativeA, comparativeB) {
        const op = this.filterDirection == 'down' ? '<' : '>';
        const list = [...this.changeMonthFilter(this.currentMonth)];
        const arr = list.sort((a, b) => eval(comparativeA + op + comparativeB) ? 1 : -1);
        return arr;
    }
    get GetFilterOption() {
        switch (this.currentFilter) {
            case 'Data venc.':
                return this.FilterByDate;
            case 'Total':
                return this.FilterByPrice;
            case 'Item':
                return this.FilterByTittle;
            case 'Status':
                return this.FilterByStatus;
        }
        return this.changeMonthFilter(this.currentMonth);
    }
    changeFilter(item) {
        if (this.currentFilter !== item) {
            this.filterDirection = 'down';
            this.currentFilter = item;
        }
        else {
            this.filterDirection = this.filterDirection == 'down' ? 'up' : 'down';
        }
    }
    getDate(date) {
        const new_date = date.includes('T') ? date.split('T') : date.split(' ');
        return getDateByFormat(new_date[0], 'DD MMMM YYYY');
    }
    getDateMonthAndYear(date) {
        const new_date = date.includes('T') ? date.split('T') : date.split(' ');
        return getDateByFormat(new_date[0], 'MMMM YYYY');
    }
    addListTitle(callList) {
        callList.forEach((el, index) => {
            callList[index]['tittle'] =
                el.items.filter(e => e.name === 'Mensalidade').length > 0
                    ? `Plano Atual - ${this.getDateMonthAndYear(el.expiration_date)}`
                    : this.customNamesForPlans[el.items[0].name] || el.items[0].name;
        });
        return callList;
    }
    async updatePaymentList() {
        const callList = 
        //@ts-ignore
        (await this.actions.getCustomerPaymentList());
        this.loadingList = false;
        if (!callList || callList.length == 0) {
            this.pageError = true;
            return;
        }
        const addListTittle = this.addListTitle(callList);
        this.paymentList = addListTittle;
        const list = [
            ...this.paymentList
                .map(item => getDateByFormat(item.expiration_date, 'MMMM'))
                .values(),
        ];
        const filter = list.filter((a, b) => list.indexOf(a) == b);
        this.filterMonthTypes = filter;
    }
    async mounted() {
        await this.updatePaymentList();
    }
    changeMonthFilter(val) {
        const filter = [
            ...this.paymentList.filter(a => getDateByFormat(a.expiration_date, 'MMMM') == val),
        ];
        if (!val || val == 'Todo o período') {
            return this.paymentList;
        }
        else {
            return filter;
        }
    }
    openBankSlipBarCode(url) {
        window.open(url, '_blank');
    }
    fiscalNoteText(status) {
        return this.fiscalNoteStatus[status];
    }
    openCreditCardFiscalNote(item) {
        if (item && item.url)
            window.open(item.url, '_blank');
    }
};
__decorate([
    Watch('currentMonth')
], AccountInformationList.prototype, "changeMonthFilter", null);
AccountInformationList = __decorate([
    Component(connect(({ actions }) => ({
        actions: actions.subscriptions,
        shop: actions.shop,
    }), {
        components: {
            IconFiscalNote,
            IconDocument,
            IconBoleto,
            IconArrowDown,
            LoadingDots,
            IconRefresh,
            Tooltip,
        },
    }))
], AccountInformationList);
export default AccountInformationList;
