var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { openClickSignEmbed } from '@/statics/clickSign';
import LoadingDots from '@/components/LoadingDots.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import { mixpanelTracking } from '@/services/mixpanel';
let SubscriptionContractPage = class SubscriptionContractPage extends Vue {
    constructor() {
        super(...arguments);
        this.pageError = false;
        this.errorMessage = {
            description: 'Caso o problema persista, entre em contato com nossa equipe de suporte.',
            message: 'Não foi possível carregar seu contrato',
        };
        this.loading = true;
    }
    async retryValidatePage() {
        this.loading = true;
        this.pageError = false;
        await this.tryToValidatePage();
    }
    async tryToValidatePage() {
        //@ts-ignore
        const subscription = await this.actions.selectPlanForUpdate({
            plan_id: this.planId,
            addParams: false,
        });
        this.loading = false;
        if (subscription &&
            subscription.contract &&
            subscription.contract.request_signature) {
            if (subscription.contract.signed) {
                this.finishPayment();
                return;
            }
            else {
                await subscription;
                this.openClickSignContract(subscription);
                mixpanelTracking('upgrade: Acessou o contrato do plano');
            }
        }
        else {
            this.sendPageError(true, 'Não foi possível carregar seu contrato', 'Caso o problema persista, entre em contato com nossa equipe de suporte.');
            mixpanelTracking('upgrade: Erro ao acessar o contrato');
        }
    }
    openClickSignContract(subscription) {
        const widget = openClickSignEmbed(subscription.contract.request_signature);
        this.$emit('createContractWidget', widget);
        widget.on('signed', ev => {
            this.signContract(subscription);
        });
    }
    async mounted() {
        await this.tryToValidatePage();
    }
    async finishPayment() {
        this.$emit('setPageStateLoading', true);
        // @ts-ignore
        const { res, errorsContent } = await this.actions.finishPlanUpdate({});
        this.$emit('setPageStateLoading', false);
        if (res.status == 200) {
            const result = res.data;
            this.$emit('finishStep', {
                billet_link: result.billet_link,
                barcode: result.barcode,
            });
            mixpanelTracking('upgrade: Novo plano contratado');
        }
        else {
            this.sendPageError(true, errorsContent.message, errorsContent.description);
            mixpanelTracking('upgrade: Erro ao contratar plano');
        }
    }
    async signContract(subscription) {
        //@ts-ignore
        const sign_contract = await this.actions.changeContractPlanStatus(subscription.selected_subscription_id);
        if (sign_contract && sign_contract.success) {
            mixpanelTracking('upgrade: Assinou contrato');
            this.finishPayment();
        }
        else {
            this.sendPageError(true, 'Um erro ocorreu durante a assinatura do contrato', '');
            mixpanelTracking('upgrade: Erro ao assinar contrato');
        }
    }
    async sendPageError(status, message = 'Um erro inesperado aconteceu', description = 'Caso o problema persista, entre em contato com nossa equipe de suporte.') {
        this.errorMessage.description = description;
        this.errorMessage.message = message;
        this.pageError = status;
    }
};
__decorate([
    Prop({ default: -1 })
], SubscriptionContractPage.prototype, "planId", void 0);
SubscriptionContractPage = __decorate([
    Component(connect(({ actions }) => ({
        actions: actions.subscriptions,
    }), {
        components: {
            LoadingDots,
            ErrorMessage,
        },
    }))
], SubscriptionContractPage);
export default SubscriptionContractPage;
