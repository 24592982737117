var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
let SelecPlanItem = class SelecPlanItem extends Vue {
    selectPlan() {
        if (!this.currentPlan)
            this.$emit('planSelected', this.index);
    }
    get clicksNumber() {
        return Number(this.planInfo.clicks) > 0 ? this.planInfo.clicks : '';
    }
    get unlimitedClicks() {
        return this.planInfo.clicks == 'unlimited' ? 'ilimitadas' : '';
    }
};
__decorate([
    Prop({ default: false })
], SelecPlanItem.prototype, "currentPlan", void 0);
__decorate([
    Prop({ default: false })
], SelecPlanItem.prototype, "recommended", void 0);
__decorate([
    Prop({ default: false })
], SelecPlanItem.prototype, "selectedPlan", void 0);
__decorate([
    Prop({
        default: 'statics/images/subscriptions/gem1.png',
    })
], SelecPlanItem.prototype, "image", void 0);
__decorate([
    Prop({ default: 0 })
], SelecPlanItem.prototype, "index", void 0);
__decorate([
    Prop({
        default: {
            clicks: '0',
            exports: '0',
        },
    })
], SelecPlanItem.prototype, "planInfo", void 0);
__decorate([
    Prop({
        default: {
            price: 0,
            discount: 0,
            superlogica_id: 0,
        },
    })
], SelecPlanItem.prototype, "planData", void 0);
SelecPlanItem = __decorate([
    Component({
        components: {},
    })
], SelecPlanItem);
export default SelecPlanItem;
