var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
import IconInfinity from '@/components/Icons/icon-infinity.vue';
import IconInformation from '@/components/Icons/IconInformation.vue';
;
[];
let AccountInformationExportInfo = class AccountInformationExportInfo extends Vue {
    constructor() {
        super(...arguments);
        this.credits = {};
        this.loading = true;
        this.exportExpire = null;
        this.clickExpire = null;
    }
    async mounted() {
        //@ts-ignore
        const result = await this.actions.getUserCreditsInformation();
        this.exportExpire = this.findExpiration(result.credits, 'export');
        this.clickExpire = this.findExpiration(result.credits, 'click');
        if (result) {
            this.credits = { ...result.credits } || {};
            this.loading = false;
        }
        else {
            //msg erro
        }
    }
    findExpiration(cred, credType) {
        return cred.reduce((prev, current) => {
            if (current.type === credType &&
                (!prev || current.expiration < prev.expiration)) {
                return current;
            }
            return prev;
        }, null);
    }
    get exportsToExpire() {
        if (this.exportExpire != null) {
            //@ts-ignore
            const date = new Date(this.exportExpire.expiration);
            date.setUTCHours(0, 0, 0, 0);
            const day = String(date.getUTCDate()).padStart(2, '0');
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const year = date.getUTCFullYear();
            return `${day}/${month}/${year}`;
        }
        else {
            return null;
        }
    }
    get clicksToExpire() {
        if (this.clickExpire != null) {
            //@ts-ignore
            const date = new Date(this.clickExpire.expiration);
            date.setUTCHours(0, 0, 0, 0);
            const day = String(date.getUTCDate()).padStart(2, '0');
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const year = date.getUTCFullYear();
            return `${day}/${month}/${year}`;
        }
        else {
            return null;
        }
    }
    get planInformationIsValid() {
        return this.credits && Object.keys(this.credits).length > 0;
    }
    get hasUnlimitedClicks() {
        const confirm = Object.values(this.credits).find(el => el.type == 'unlimited_click') || {};
        return Object.keys(confirm).length > 0;
    }
    get clicksObject() {
        return Object.values(this.credits).filter(el => el.type == 'click') || {};
    }
    get clicks() {
        const amount = this.clicksObject.reduce((a, b) => a + b.remaining, 0);
        return this.totalClicks - amount;
    }
    get totalClicks() {
        const amount = this.clicksObject.reduce((a, b) => a + b.value, 0);
        return amount;
    }
    get exportsObject() {
        return Object.values(this.credits).filter(el => el.type == 'export') || {};
    }
    get exports() {
        const amount = this.exportsObject.reduce((a, b) => a + b.remaining, 0);
        return this.totalExports - amount;
    }
    get totalExports() {
        const amount = this.exportsObject.reduce((a, b) => a + b.value, 0);
        return amount;
    }
    get clicksPercentage() {
        return (this.clicks / this.totalClicks) * 100;
    }
    get exportsPercentage() {
        return (this.exports / this.totalExports) * 100 || 0;
    }
};
AccountInformationExportInfo = __decorate([
    Component(connect(({ state, actions }) => ({
        actions: actions.users,
    }), {
        components: {
            IconInfinity,
            IconInformation,
        },
    }))
], AccountInformationExportInfo);
export default AccountInformationExportInfo;
