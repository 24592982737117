var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('NewModal',{attrs:{"open":_vm.showModal,"title":"Atualizar plano","width":1042,"height":720,"headerHeight":92,"footerHeight":100,"loading":_vm.loading},on:{"update:open":function($event){_vm.showModal=$event},"closeBtn":function($event){return _vm.modalCloseBtn()}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('button',{on:{"click":_vm.handleClose}},[_vm._v("\n        "+_vm._s(_vm.stepInProgress
            ? _vm.hasCancelButton
              ? 'Cancelar'
              : 'Voltar'
            : 'Fechar')+"\n      ")]),(_vm.stepInProgress && !_vm.isInContractPage)?_c('button',{attrs:{"disabled":_vm.continueBtnDisabled},on:{"click":_vm.continueButtonClicked}},[_vm._v("\n        Continuar\n      ")]):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"shop-payment"},[_c('div',{staticClass:"shop-payment-tabs"},[_c('div',{staticClass:"shop-payment-tabs-list"},_vm._l((_vm.stepList),function(item,index){return _c('div',{key:index,staticClass:"shop-payment-tabs-list-item",class:{
              active: index == _vm.currentStep,
              checked: _vm.currentStep > index,
            }},[_c('checkbox-progress',{staticClass:"shop-payment-checkbox",attrs:{"active":index == _vm.currentStep,"checked":_vm.currentStep > index}}),_vm._v("\n            "+_vm._s(item)+"\n          ")],1)}),0),_c('div',{staticClass:"shop-payment-tabs-banner"},[_c('img',{attrs:{"src":require("@/statics/images/subscriptions/academy-banner.png"),"alt":""}})])]),_c('div',{staticClass:"shop-payment-content"},[_c(_vm.componentList[_vm.currentStep],{ref:((_vm.componentList[_vm.currentStep]) + "Ref"),tag:"component",attrs:{"paymentInfo":_vm.selectedPlanInfo,"barcode":_vm.paymentType.barcode,"billet_link":_vm.paymentType.billet_link,"planId":_vm.selectedPlanInfo.planId,"nextPlan":_vm.nextPlan},on:{"accountPreferecesNextStep":_vm.accountPreferecesNextStep,"subscriptionNextStep":_vm.subscriptionNextStep,"disableButton":_vm.disableButton,"loadPlanDetails":_vm.loadPlanDetails,"setPageStateLoading":_vm.setPageStateLoading,"finishStep":_vm.finishStep,"createContractWidget":_vm.setContractWidget}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }