var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SelectPlanItem from '@/modules/subscriptions/components/SelectPlanItem.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import { overmind } from '@/overmind';
let SelectPlanContainer = class SelectPlanContainer extends Vue {
    constructor() {
        super(...arguments);
        this.planDurationList = ['Anual', 'Semestral', 'Trimestral', 'Mensal'];
        this.planDuration = 'Anual';
        this.currentPlan = '';
        this.plans = [];
        this.checked = '';
        this.planIndex = -1;
        this.imgList = [
            'statics/images/subscriptions/gem1.png',
            'statics/images/subscriptions/gem2.png',
            'statics/images/subscriptions/gem3.png',
            'statics/images/subscriptions/gem4.png',
            'statics/images/subscriptions/gem5.png',
            'statics/images/subscriptions/gem6.png',
            'statics/images/subscriptions/gem7.png',
            'statics/images/subscriptions/gem8.png',
        ];
    }
    mounted() {
        mixpanelTracking('upgrade: Acessou seleção de planos');
        this.getPlans();
    }
    async getPlans() {
        const plans = await overmind.actions.subscriptions.getPlans();
        plans.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        const removeAnyPlans = plans.filter(item => !['Test'].includes(item.name));
        this.plans = removeAnyPlans;
    }
    recommendedPlan(item) {
        return item.credits.clicks === '300' && item.credits.clicks === '300';
    }
    get CurrentPlan() {
        const result = this.plans.findIndex(item => item.name === this.currentPlan);
        return Number(result);
    }
    planSelected(index) {
        this.planIndex = index;
        this.checked = this.getPlanId(index);
        this.handleContinueBtn(index);
    }
    getPlanId(index) {
        const recurrence = this.plans[index].recurrence.find(r => r.name == this.planDuration);
        return recurrence.id;
    }
    handleContinueBtn(val) {
        const handler = val > -1;
        this.$emit('disableButton', !handler);
    }
    handlePlanDuration() {
        const selected = this.checked === this.getPlanId(this.planIndex);
        this.$emit('disableButton', !selected);
    }
    getPlanData(item) {
        return item.recurrence.find(r => r.name == this.planDuration);
    }
    handleButton() {
        if (!(this.planIndex > -1))
            return;
        const attributes = this.plans[this.planIndex];
        const clicks = attributes.credits.clicks == 'unlimited'
            ? 'ilimitado'
            : attributes.credits.clicks;
        const items = [
            {
                name: 'Exportações',
                recurrence: true,
                value: attributes.credits.exports,
            },
            {
                name: 'Consultas',
                recurrence: true,
                value: clicks,
            },
            {
                name: 'Qtde. de usuários',
                recurrence: false,
                value: '1',
            },
        ];
        const planInfo = attributes.recurrence.find(r => r.name == this.planDuration);
        const description = {
            planName: 'Plano',
            value: this.planDuration,
            discount: planInfo.discount,
        };
        // @ts-ignore
        const planBase = attributes.recurrence.find(r => r.name == attributes.discount_base).price;
        const orderSummary = [
            {
                name: 'Total por mês',
                value: planInfo.price,
            },
        ];
        const prices = [];
        if (planBase - planInfo.price > 0) {
            prices.push({
                name: 'Valor do plano por mês',
                value: planBase,
                isDiscount: false,
            }, {
                name: 'Desconto do plano',
                value: planBase - planInfo.price,
                isDiscount: true,
            });
        }
        this.$emit('loadPlanDetails', { items, description, prices, orderSummary }, this.checked);
    }
};
__decorate([
    Prop({ default: {} })
], SelectPlanContainer.prototype, "nextPlan", void 0);
__decorate([
    Watch('planDuration')
], SelectPlanContainer.prototype, "handlePlanDuration", null);
SelectPlanContainer = __decorate([
    Component({
        components: {
            SelectPlanItem,
        },
    })
], SelectPlanContainer);
export default SelectPlanContainer;
