var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AccountInformationPaymentMethod from '../components/AccountInformation/Details/AccountInformationPaymentMethod.vue';
import PaymentDescription from '@/modules/subscriptions/components/PaymentDescription.vue';
import Checkbox from '@/components/Checkbox.vue';
import { mixpanelTracking } from '@/services/mixpanel';
let PaymentMethodContainer = class PaymentMethodContainer extends Vue {
    constructor() {
        super(...arguments);
        this.fixed = false;
        this.acceptedTerms = false;
        this.editPaymentInformation = false;
        this.saveTerms = false;
        this.activedMethod = -1;
        this.paymentsMethods = [
            {
                currentMethod: true,
                creditCard: true,
            },
            {
                creditCard: false,
            },
        ];
        this.creditCardData = {
            name: '',
        };
    }
    getEditModeInfo(val) {
        this.editPaymentInformation = val;
    }
    acceptTerms() {
        this.fixed = false;
        this.acceptedTerms = true;
    }
    refuseTerms() {
        this.fixed = false;
        this.acceptedTerms = false;
    }
    openTerms() {
        this.fixed = true;
    }
    async beforeMount() {
        this.$emit('disableButton', true);
    }
    selectMethod(index) {
        if (isNaN(index))
            this.activedMethod = -1;
        else
            this.activedMethod = index;
    }
    disableTermsAcceptable(val, old) {
        if (val) {
            this.saveTerms = this.acceptedTerms;
            this.acceptedTerms = false;
        }
        else {
            this.acceptedTerms = this.saveTerms;
        }
    }
    handlerEnableButton(val) {
        this.$emit('disableButton', !val);
    }
    async handleButton() {
        this.$emit('subscriptionNextStep');
    }
    mounted() {
        mixpanelTracking('upgrade: Acessou método de pagamento');
    }
};
__decorate([
    Prop({ default: '' })
], PaymentMethodContainer.prototype, "terms_of_use", void 0);
__decorate([
    Prop({
        default: () => {
            return {
                items: [],
                warning: '',
                description: {
                    planName: '',
                    value: '',
                },
                prices: [],
                planTotal: 0,
            };
        },
    })
], PaymentMethodContainer.prototype, "paymentInfo", void 0);
__decorate([
    Watch('editPaymentInformation')
], PaymentMethodContainer.prototype, "disableTermsAcceptable", null);
__decorate([
    Watch('acceptedTerms')
], PaymentMethodContainer.prototype, "handlerEnableButton", null);
PaymentMethodContainer = __decorate([
    Component({
        components: {
            PaymentDescription,
            Checkbox,
            AccountInformationPaymentMethod,
        },
    })
], PaymentMethodContainer);
export default PaymentMethodContainer;
