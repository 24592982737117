var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Watch } from 'vue-property-decorator';
import InputLabel from '@/components/InputLabel.vue';
import ErrorBanner from '@/components/ErrorBanner.vue';
import { AllStates, validatorCNPJ, validatorCPF } from '@/shared/utils/helpers';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
let AccountPreferencesUpdateContainer = class AccountPreferencesUpdateContainer extends Vue {
    constructor() {
        super(...arguments);
        this.accountForm = {
            contractor_name: '',
            email: '',
            phone_number: '',
            contractor_document: '',
            business_name: '',
        };
        this.addressForm = {
            zipcode: '',
            neighborhood: '',
            street_address: '',
            house_number: '',
            complemental_address: '',
            city: '',
            state: '',
            country: 'Brasil',
        };
        this.accountFormInputs = [
            {
                contractor_name: 'Nome completo / Razão Social',
            },
            {
                email: 'E-mail',
                phone_number: 'Celular',
            },
            {
                contractor_document: 'CNPJ',
                business_name: 'Nome Fantasia',
            },
        ];
        this.addressFormInputs = [
            {
                zipcode: 'CEP',
                neighborhood: 'Bairro',
            },
            {
                street_address: 'Endereço',
            },
            {
                house_number: 'Número',
                complemental_address: 'Complemento',
            },
            {
                city: 'Cidade',
                state: 'UF',
            },
        ];
        this.masks = {
            cpf: '###.###.###-###',
            cnpj: '##.###.###/####-##',
            phone_number: '(##) # ####-####',
            zipcode: '#####-###',
            house_number: '######',
            state: 'AA',
        };
        this.inputErrorFlag = '';
        this.currentErrorMessage = '';
        this.errorMessages = {
            fullName: 'O campo <i>Nome completo / Razão Social</i> não pode ser vazio, conter somente uma palavra ou conter a primeira ou a segunda palavra abreviada.',
            emptyField: 'Preencha todos os campos',
            wrongCEP: 'O CEP digitado não corresponde a um endereço conhecido. Revise os dados e tente novamente.',
            phoneNumber: 'Insira um número de celular válido, no formato (DD) 9 ou 6 XXXX-XXXX',
            documentType: 'O campo <i>CNPJ</i> é inválido.',
            invalidCPF: 'O campo <i>CPF</i> não é válido.',
            invalidCNPJ: 'O campo <i>CNPJ</i> não é válido.',
            invalidState: 'O campo <i>UF</i> não é válido.',
            error: 'Ocorreu um erro durante a confirmação dos seus dados, por favor, tente novamente. Se o problema persistir entre em contato com a nossa equipe.',
        };
        this.fieldsRegexList = {
            fullName: /^\S{2,}\s+\S{2,}/,
            emptyField: /[\S\s]+[\S]+/,
            phoneNumber: /^\(\d{2}\)\s[9,6]\s\d{4}-\d{4}$/,
            zipcode: /^[0-9]{5}-[0-9]{3}$/,
            documentType: /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/,
            cpf: /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}/,
            cnpj: /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}/,
            wrongCEP: /^[0-9]{5}-[0-9]{3}$/,
        };
        this.regexItemByFieldName = {
            contractor_name: 'fullName',
            phone_number: 'phoneNumber',
            contractor_document: 'documentType',
            business_name: 'emptyField',
            zipcode: 'wrongCEP',
            street_address: 'emptyField',
            house_number: 'emptyField',
            neighborhood: 'emptyField',
            city: 'emptyField',
            state: 'emptyField',
        };
    }
    get currentDocumentType() {
        const document = this.accountForm['contractor_document'];
        if (document) {
            return 'CNPJ';
        }
        else {
            return '';
        }
    }
    currentMask(ref) {
        if (ref == 'contractor_document') {
            return this.accountForm[ref] && this.accountForm[ref].length > 14
                ? this.masks['cnpj']
                : this.masks['cpf'];
        }
        return this.masks[ref];
    }
    disabledInputs(ref) {
        const disableFantasyName = ref == 'business_name' && this.currentDocumentType !== 'CNPJ';
        const disableEmail = ref == 'email';
        return disableEmail || disableFantasyName;
    }
    beforeMount() {
        this.eventListener = e => {
            if (e.key === 'Enter') {
                this.handleButton();
            }
        };
        window.addEventListener('keypress', this.eventListener, false);
    }
    beforeDestroy() {
        window.removeEventListener('keypress', this.eventListener, false);
    }
    async mounted() {
        mixpanelTracking('upgrade: Acessou revisão dos dados');
        //@ts-ignore
        const account_preferences = await this.user.getUserAccountPreferences();
        if (account_preferences.data) {
            const account_data = account_preferences.data.data
                .attributes;
            for (let each in this.accountForm)
                this.accountForm[each] = account_data[each] || '';
            this.addressForm.zipcode = account_data.zipcode || '';
            const newForm = { ...this.addressForm };
            setTimeout(() => {
                delete newForm.zipcode;
                for (let each in newForm)
                    this.addressForm[each] = account_data[each] || '';
            }, 500);
        }
    }
    getRequiredFields(field) {
        const nonRequired = 'complemental_address';
        const customRequired = 'business_name';
        let isCustomFieldRequired = false;
        if (customRequired == field && this.currentDocumentType === 'CNPJ') {
            isCustomFieldRequired = true;
        }
        return !(field == nonRequired ||
            (field == customRequired && !isCustomFieldRequired));
    }
    async handleButton() {
        const validate = await this.validateFields();
        if (validate) {
            const data = { ...this.accountForm, ...this.addressForm };
            //@ts-ignore
            const account_preferences = await this.user.setUserAccountPreferences(data);
            if (account_preferences &&
                account_preferences.status == 201 &&
                account_preferences.data)
                this.$emit('accountPreferecesNextStep');
            else {
                this.currentErrorMessage = this.errorMessages.error;
            }
        }
    }
    getValidatedField(field) {
        const fieldsList = { ...this.accountForm, ...this.addressForm };
        const fieldIdentifier = this.regexItemByFieldName[field];
        const regex = this.fieldsRegexList[fieldIdentifier];
        const errorMessage = this.errorMessages[fieldIdentifier];
        this.inputErrorFlag = '';
        this.currentErrorMessage = '';
        if (regex && errorMessage) {
            if (!regex.test(fieldsList[field])) {
                this.inputErrorFlag = field;
                this.currentErrorMessage = errorMessage;
                return false;
            }
            return true;
        }
        return true;
    }
    async validateFields() {
        if (!this.validateAccountFields())
            return false;
        if (!this.validateDocument())
            return false;
        const validateAddress = await this.validateAddressFields();
        if (!validateAddress)
            return false;
        return true;
    }
    validateAccountFields() {
        const accFields = {
            contractor_name: '',
            email: '',
            phone_number: '',
            contractor_document: '',
        };
        for (let each in accFields) {
            const validate = this.getValidatedField(each);
            if (!validate) {
                return false;
            }
        }
        return true;
    }
    async validateAddressFields() {
        for (let each in this.addressForm) {
            const validate = this.getValidatedField(each);
            if (!validate) {
                return false;
            }
        }
        const cepNumber = this.addressForm['zipcode'].replace(/[^0-9]/g, '');
        const res = await fetch(`https://brasilapi.com.br/api/cep/v2/${cepNumber}`);
        if (!res.ok) {
            this.inputErrorFlag = 'zipcode';
            this.currentErrorMessage = this.errorMessages.wrongCEP;
            return false;
        }
        const states = AllStates();
        const verifyStateExists = states.some(el => el.value == this.addressForm['state']);
        if (!verifyStateExists) {
            this.inputErrorFlag = 'state';
            this.currentErrorMessage = this.errorMessages.invalidState;
            return false;
        }
        return true;
    }
    validateDocument() {
        const document = this.currentDocumentType;
        const documentField = this.accountForm['contractor_document'].replace(/[^0-9]/g, '');
        const validateCNPJ = validatorCNPJ(documentField);
        const validateCPF = validatorCPF(documentField);
        if (document === 'CNPJ' && !validateCNPJ) {
            this.inputErrorFlag = 'contractor_document';
            this.currentErrorMessage = this.errorMessages['invalidCNPJ'];
            return false;
        }
        const regexCNPJ = this.fieldsRegexList['cnpj'];
        const regexCPF = this.fieldsRegexList['cpf'];
        if (regexCNPJ.test(this.accountForm['contractor_document'])) {
            const validate = this.getValidatedField('business_name');
            if (!validate) {
                return false;
            }
        }
        else if (regexCPF.test(this.accountForm['contractor_document'])) {
            this.accountForm['business_name'] = '';
        }
        return true;
    }
    async cepValidation() {
        const cepNumber = this.addressForm['zipcode'].replace(/[^0-9]/g, '');
        if (cepNumber.length === 8) {
            const res = await fetch(`https://brasilapi.com.br/api/cep/v2/${cepNumber}`);
            if (res.ok) {
                const data = await res.json();
                this.addressForm.state = data.state || '';
                this.addressForm.neighborhood = data.neighborhood || '';
                this.addressForm.city = data.city || '';
                this.addressForm.street_address = data.street || '';
            }
        }
        else {
            this.addressForm.city = '';
            this.addressForm.neighborhood = '';
            this.addressForm.state = '';
            this.addressForm.street_address = '';
        }
    }
};
__decorate([
    Watch('addressForm.zipcode')
], AccountPreferencesUpdateContainer.prototype, "cepValidation", null);
AccountPreferencesUpdateContainer = __decorate([
    Component(connect(({ actions }) => ({
        user: actions.users,
    }), {
        components: {
            InputLabel,
            ErrorBanner,
        },
    }))
], AccountPreferencesUpdateContainer);
export default AccountPreferencesUpdateContainer;
