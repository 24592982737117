var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import AccountUpgradeModal from '../../containers/modals/AccountUpgradeModal.container.vue';
import { connect } from '@/overmind';
import { showNotify } from '@/shared/utils/helpers';
import IconPencil from '@/components/Icons/IconPencil.vue';
import IconClose from '@/components/Icons/IconClose.vue';
import Modal from '@/components/Modal.vue';
import OnboardStepItem from '@/components/Onboarding/OnboardStepItem.vue';
import ChangePlanBtn from '@/modules/upgradeplans/ChangePlanBtn.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import axios from 'axios';
Vue.prototype.$axios = axios.create();
let AccountInformationHeader = class AccountInformationHeader extends Vue {
    constructor() {
        super(...arguments);
        this.openUpgradeAccount = false;
        this.currentPlan = '';
        this.hasPlan = '';
        this.showOnboardModal = false;
        this.slide = 1;
        this.selectedOption = false;
        this.refusedOnboarding = false;
        this.successUserPage = false;
        this.loaded = false;
    }
    async beforeMount() {
        //@ts-ignore
        this.hasPlan = this.$auth.user().accountType;
        const userRes = await Vue.axios.get('account_plan/current');
        this.currentPlan = userRes.data.plan.name;
    }
    created() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const plan_upgrade = urlParams.get('plan_upgrade');
        if (plan_upgrade) {
            setTimeout(() => {
                this.tryToOpenModal('update');
            }, 1200);
        }
    }
    get hasChangePlanAuthPermission() {
        //@ts-ignore
        const user = this.$auth.user();
        return user.role == 'owner' || user.role == 'admin';
    }
    tryToOpenModal(emitType) {
        if (!this.hasChangePlanAuthPermission) {
            showNotify('Apenas proprietários podem alterar o plano!', 'error');
            return;
        }
        // @ts-ignore
        if (['free', 'undefined'].includes(this.hasPlan) && emitType == 'update') {
            this.openUpgradeAccount = true;
            mixpanelTracking('upgrade: Abriu o upgrade de planos');
        }
        else {
            this.$emit('openJivoPlanUpdate', emitType);
        }
        //this.$emit('openJivoPlanUpdate', emitType)
    }
    handleRandomCalendyUrls() {
        const urls = [
            'https://speedio.com.br/agenda/gabriel-luz/',
            'https://calendar.google.com/calendar/u/0/appointments/AcZssZ0rnU3pM6P-Kjwz_Uc7wyxAeDu-w_Z4tqoKWvU=',
            'https://calendar.google.com/calendar/u/0/appointments/AcZssZ3E90BF5U479E50umiv2firopy3PL0H2QxOKkA=',
            'https://calendar.google.com/calendar/u/0/appointments/AcZssZ2cv13NF5wHfCqEqTQHDuWu8_zLlpQ4Vbn5KCU=',
            'https://speedio.com.br/agenda/matheus-miranda/',
            'https://calendar.google.com/calendar/u/0/appointments/AcZssZ3rNPBRX37KGdMKl4PIpCtiypLOnd64_jJcqzA=',
            'https://calendar.google.com/calendar/u/0/appointments/AcZssZ2nDjuw4ibkwYlJzzJp4Ln2-gPjcuLxUlGTdCY=',
        ];
        let redirectTo = urls[this.handleRandomRange(0, urls.length)];
        const handleWindow = () => window.open(redirectTo, '_blank');
        if (redirectTo)
            handleWindow();
        else {
            redirectTo = urls[this.handleRandomRange(0, urls.length)];
            handleWindow();
        }
    }
    handleRandomRange(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    onboardingConfirmation() {
        this.handleRandomCalendyUrls();
        return true;
    }
    handleSelectedOption() {
        this.showOnboardModal = !this.selectedOption;
    }
    closeModal() {
        this.showOnboardModal = false;
        this.selectedOption = false;
        this.openUpgradeAccount = false;
        this.hasPlan = '';
        this.showOnboardModal = false;
        this.slide = 1;
        this.selectedOption = false;
        this.refusedOnboarding = false;
        this.successUserPage = false;
        this.loaded = false;
    }
};
__decorate([
    Prop({ default: {} })
], AccountInformationHeader.prototype, "nextPlan", void 0);
__decorate([
    Emit('confirmation')
], AccountInformationHeader.prototype, "onboardingConfirmation", null);
AccountInformationHeader = __decorate([
    Component(connect(({ actions }) => ({
        shop: actions.shop,
    }), {
        components: {
            AccountUpgradeModal,
            IconPencil,
            IconClose,
            Modal,
            OnboardStepItem,
            ChangePlanBtn,
        },
    }))
], AccountInformationHeader);
export default AccountInformationHeader;
