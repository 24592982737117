var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
let AccountInformationPlanInfo = class AccountInformationPlanInfo extends Vue {
    constructor() {
        super(...arguments);
        this.planType = this.currentPlan.plan.name == 'Free' ? 'Free' : 'Paid';
    }
    get isNotAFreePlan() {
        return this.planType !== 'Free';
    }
    get nextPlanClicks() {
        if (this.currentPlan.plan.name == 'Enterprise')
            return 0;
        //@ts-ignore
        return this.nextPlanBanner.metadata.click_credits;
    }
    get nextPlanExports() {
        if (this.currentPlan.plan.name == 'Enterprise')
            return 0;
        //@ts-ignore
        return this.nextPlanBanner.metadata.export_credits;
    }
    get nextPlanPrice() {
        if (this.currentPlan.plan.name == 'Enterprise')
            return 0;
        return this.nextPlanBanner.price;
    }
    get bannerAnnounce() {
        return this.nextPlanBanner.is_custom
            ? 'Precisa de mais créditos de consulta e exportação?'
            : this.isNotAFreePlan
                ? 'Escale seus resultados prospectando mais empresas'
                : 'Aumente suas vendas com a Speedio';
    }
    upgradeCurrentPlan() {
        this.$router.push('/upgrade_plans');
    }
};
__decorate([
    Prop({ default: {} })
], AccountInformationPlanInfo.prototype, "currentPlan", void 0);
__decorate([
    Prop({ default: {} })
], AccountInformationPlanInfo.prototype, "nextPlanBanner", void 0);
AccountInformationPlanInfo = __decorate([
    Component({
        name: 'AccountInformationPlanInfo',
    })
], AccountInformationPlanInfo);
export default AccountInformationPlanInfo;
