var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import CheckboxProgress from '@/components/CheckboxProgress.vue';
import NewModal from '@/components/NewModal.vue';
import PaymentMethodContainer from '@/modules/subscriptions/containers/SelectPlanPaymentMethod.container.vue';
import BankSlipPayment from '@/components/Subscriptions/BankSlipPayment.vue';
import CreditCardPayment from '@/components/Subscriptions/CreditCardPayment.vue';
import SubscriptionContractPage from '../SelectPlanContractPage.container.vue';
import SelectPlanContainer from '../SelectPlan.container.vue';
import AccountPreferencesUpdate from '../AccountPreferencesUpdate.container.vue';
import { overmind } from '@/overmind';
let AccountUpgradeContainer = class AccountUpgradeContainer extends Vue {
    constructor() {
        super(...arguments);
        this.contractWidget = null;
        this.loading = false;
        this.continueBtnDisabled = true;
        this.stepList = [
            'Escolher novo plano',
            'Revisar dados',
            'Revisar dados de pagamento',
            'Atualizar contrato',
        ];
        this.componentList = [
            'SelectPlanContainer',
            'AccountPreferencesUpdate',
            'PaymentMethodContainer',
            'SubscriptionContractPage',
            'BankSlipPayment',
            'CreditCardPayment',
        ];
        this.currentStep = 0;
        this.selectedPlanInfo = {
            items: [],
            warning: '',
            description: {
                planName: '',
                value: '',
            },
            prices: [],
            orderSummary: [],
        };
        this.paymentType = {};
    }
    async mounted() {
        //registrando evento no tag manager
        //@ts-ignore
        window.dataLayer.push({ event: 'abriu_modal_upgrade_planos' });
    }
    async beforeMount() {
        const account_plan = await overmind.actions.subscriptions.getUserPlanSelected();
        if (account_plan.status == 200) {
            if (account_plan.data) {
                this.selectedPlanInfo['planId'] =
                    account_plan.data.superlogica_id || account_plan.data.item_id;
                this.currentStep = 3;
            }
        }
        else {
            this.showModal = false;
        }
    }
    get stepInProgress() {
        return this.currentStep <= this.stepList.length - 1;
    }
    get isInContractPage() {
        return this.currentStep == 3;
    }
    setContractWidget(widget) {
        this.contractWidget = widget;
    }
    unSetContractWidget() {
        if (this.contractWidget) {
            this.contractWidget.unmount();
        }
    }
    disableButton(result) {
        this.continueBtnDisabled = result;
    }
    setPageStateLoading(state) {
        this.loading = state;
    }
    continueButtonClicked() {
        //@ts-ignore
        this.$refs[this.componentList[this.currentStep] + 'Ref'].handleButton();
    }
    get hasCancelButton() {
        return this.isInContractPage || this.currentStep == 0;
    }
    modalCloseBtn() {
        this.unSetContractWidget();
        if (!this.stepInProgress)
            window.location.reload();
    }
    handleClose() {
        this.unSetContractWidget();
        if (!this.stepInProgress)
            window.location.reload();
        else if (this.hasCancelButton) {
            this.showModal = false;
        }
        else {
            this.currentStep--;
            if (this.currentStep == 1) {
                this.continueBtnDisabled = false;
            }
            else {
                this.continueBtnDisabled = true;
            }
        }
    }
    accountPreferecesNextStep() {
        this.currentStep++;
    }
    loadPlanDetails(data, planId) {
        this.selectedPlanInfo = data;
        this.selectedPlanInfo['planId'] = planId;
        this.currentStep++;
    }
    finishStep(paymentType) {
        this.paymentType = paymentType;
        if (paymentType.barcode || paymentType.billet_link) {
            this.currentStep = this.componentList.indexOf('BankSlipPayment');
        }
        else {
            this.currentStep = this.componentList.indexOf('CreditCardPayment');
        }
    }
    subscriptionNextStep() {
        this.currentStep++;
    }
};
__decorate([
    PropSync('open', { default: false })
], AccountUpgradeContainer.prototype, "showModal", void 0);
__decorate([
    Prop({ default: {} })
], AccountUpgradeContainer.prototype, "nextPlan", void 0);
AccountUpgradeContainer = __decorate([
    Component({
        components: {
            Modal,
            CheckboxProgress,
            NewModal,
            PaymentMethodContainer,
            BankSlipPayment,
            CreditCardPayment,
            SubscriptionContractPage,
            SelectPlanContainer,
            AccountPreferencesUpdate,
        },
    })
], AccountUpgradeContainer);
export default AccountUpgradeContainer;
