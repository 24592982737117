import { render, staticRenderFns } from "./InputLabel.vue?vue&type=template&id=5caf7478&scoped=true&"
import script from "./InputLabel.vue?vue&type=script&lang=ts&"
export * from "./InputLabel.vue?vue&type=script&lang=ts&"
import style0 from "./InputLabel.vue?vue&type=style&index=0&id=5caf7478&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5caf7478",
  null
  
)

export default component.exports