var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import PlanInfo from './Details/AccountInformationPlanInfo.vue';
import PaymentMethod from './Details/AccountInformationPaymentMethod.vue';
import ExportInfo from './Details/AccountInformationExportInfo.vue';
import { connect } from '@/overmind';
let AccountInformationDetails = class AccountInformationDetails extends Vue {
    constructor() {
        super(...arguments);
        this.hideExportInfo = false;
    }
    getEditModeInfo(val) {
        this.hideExportInfo = val;
    }
    get hasIntegrationId() {
        // @ts-ignore
        const { superlogica_id, customer_id } = this.currentUser || {};
        return !!superlogica_id || !!customer_id;
    }
};
__decorate([
    Prop()
], AccountInformationDetails.prototype, "currentPlan", void 0);
__decorate([
    Prop()
], AccountInformationDetails.prototype, "allowedUsers", void 0);
AccountInformationDetails = __decorate([
    Component(connect(({ actions, state }) => ({
        actions: actions.shop,
        currentUser: state.users.currentUser,
    }), {
        components: {
            PlanInfo,
            PaymentMethod,
            ExportInfo,
        },
    }))
], AccountInformationDetails);
export default AccountInformationDetails;
