var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import IconStore from '@/components/Icons/IconStore.vue';
import IconUsers from '@/components/Icons/IconUsers.vue';
var recurrenceConvert;
(function (recurrenceConvert) {
    recurrenceConvert["monthly"] = "Mensal";
    recurrenceConvert["quarterly"] = "Trimestral";
    recurrenceConvert["semiannual"] = "Semestral";
    recurrenceConvert["annual"] = "Anual";
    recurrenceConvert["free"] = "Teste gratuito";
})(recurrenceConvert || (recurrenceConvert = {}));
let AccountInformationPlanInfo = class AccountInformationPlanInfo extends Vue {
    constructor() {
        super(...arguments);
        this.infoLoaded = false;
    }
    finishLoading(after, before) {
        if (!after)
            return;
        if (!after.plan)
            return;
        if (after.plan.name == '')
            return;
        this.infoLoaded = true;
    }
    async mounted() {
        if (this.currentPlan && this.currentPlan.plan.name !== '') {
            this.infoLoaded = true;
        }
    }
    getTestAccount() {
        return this.currentPlan.plan.name == 'Free';
    }
    convertDate(day) {
        if (day > 9) {
            return day;
        }
        else {
            return '0' + day;
        }
    }
    get additionalServiceMessage() {
        const services = this.currentPlan.additionals;
        if (services <= 0) {
            return 'Nenhum serviço adicional';
        }
        else if (services == 1) {
            return '1 serviço adicional';
        }
        else {
            return `${services} serviços adicionais`;
        }
    }
    get planRecurrence() {
        return this.currentPlan.plan.recurrence;
    }
    get planPrice() {
        return this.currentPlan.plan.price || 0;
    }
    get expirationDate() {
        return this.currentPlan.plan.expiration_date;
    }
    get allowedUsersText() {
        const users = this.allowedUsers;
        if (users == 1) {
            return '1 usuário';
        }
        else if (users <= 999) {
            return `${users} usuários`;
        }
        else {
            return 'Usuários ilimitados';
        }
    }
};
__decorate([
    Prop({ default: {} })
], AccountInformationPlanInfo.prototype, "currentPlan", void 0);
__decorate([
    Prop({ default: 0 })
], AccountInformationPlanInfo.prototype, "allowedUsers", void 0);
__decorate([
    Watch('currentPlan')
], AccountInformationPlanInfo.prototype, "finishLoading", null);
AccountInformationPlanInfo = __decorate([
    Component({
        components: {
            IconStore,
            IconUsers,
        },
    })
], AccountInformationPlanInfo);
export default AccountInformationPlanInfo;
