var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/modules/subscriptions/components/AccountInformation/AccountInformationHeader.vue';
import Details from '@/modules/subscriptions/components/AccountInformation/AccountInformationDetails.vue';
import Banner from '@/modules/subscriptions/components/AccountInformation/AccountInformationBanner.vue';
import List from '@/modules/subscriptions/components/AccountInformation/AccountInformationList.vue';
import Jivo from '@/shared/utils/JivoChat.utils';
import { overmind } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
let AccountInformationContainer = class AccountInformationContainer extends Vue {
    constructor() {
        super(...arguments);
        this.currentPlan = null;
        this.nextPlan = {
            product_id: 0,
            price: 0,
            difference_price: 0,
            credits: {},
            discount: 0,
            is_custom: false,
        };
        this.nextPlanBanner = {
            id: 0,
            is_custom: false,
            name: '',
            price: 0,
            recurrence: '',
            metadata: {
                click_credits: '',
                export_credits: '',
                additional_users: 0,
                suport_type: '',
                dedicated_specialist: false,
            },
        };
        this.allowedUsers = 0;
    }
    async mounted() {
        mixpanelTracking('painel do usuário: Abriu a aba Visão geral da conta');
        this.currentPlan = await overmind.actions.shop.getCurrentPlan();
        if (this.currentPlan) {
            this.allowedUsers = this.currentPlan.allowed_users;
            this.nextPlan = await overmind.actions.shop.getNextPlan(this.currentPlan.plan.credits.exports);
            this.nextPlanBanner = await overmind.actions.shop.getNextPlan(this.currentPlan.plan.credits.exports);
        }
    }
    async upgradeCurrentPlan(message = 'update') {
        // @ts-ignore
        if (message == 'update')
            await overmind.actions.users.clickedOnUpgrade();
        this.showJivoMessage(message);
    }
    openUpgradeModal() {
        //@ts-ignore
        this.$refs['headerUpgradePlanModal'].tryToOpenModal('update');
    }
    showJivoMessage(action) {
        // @ts-ignore
        // window.jivo_api.open()
        // let msg = ''
        // if (action === 'update') {
        //   msg = 'Estou à disposição! Para confirmar, envie aqui "alterar plano"'
        // } else {
        //   msg = 'Estou à disposição! Para confirmar, envie aqui "cancelar plano"'
        // }
        // //let messageNode = document.createTextNode(msg);
        // let welcomeDiv = Jivo.getWelcomeMsg(msg)!
        // window.HubSpotConversations.widget.load({ widgetOpen: true })
    }
    beforeDestroy() {
        //this.dispose()
        // @ts-ignore
        if (window.jivo_api) {
            // @ts-ignore
            window.jivo_api.close();
        }
        const jivoContainer = Jivo.getJivoContainer();
        let welcomeDiv = null;
        if (jivoContainer) {
            welcomeDiv = jivoContainer.querySelector('#jivo-container--welcome');
            if (welcomeDiv)
                welcomeDiv.remove();
        }
    }
    async confirmation() {
        // @ts-ignore
        await overmind.actions.users.onboardingRegister({
            selectedOption: 'scheduled',
        });
    }
};
AccountInformationContainer = __decorate([
    Component({
        components: {
            Header,
            Details,
            Banner,
            List,
        },
    })
], AccountInformationContainer);
export default AccountInformationContainer;
